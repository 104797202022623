import React, { Component } from 'react';
import '././../../../../Styles/HallwayGame.scss';

class HallwayGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CurrentTask: {},
            taskId: 0
        };
        this.bindEvents();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            if (props.CurrentTask !== undefined) {
                return {
                    taskId: props.CurrentTask.id
                }
            }
        }
        return null;
    }

    bindEvents = () => {
        window.addEventListener("message", this.onMessageReceived, false);
    }

    onMessageReceived = async (event) => {
        if (event.data.length > 0) {
            const isCorrect = (event.data.toLowerCase().indexOf("completed") > 0);
            if (isCorrect) {
               await this.props.markAsCompleted(this.state.taskId);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="container">
                    <div className="page_content">
                        <div className={"videos-holder assess-threat-holder"}
                            id="assess-threat"
                        >
                            <iframe
                                src={process.env.REACT_APP_HALLWAY_GAME_URL}
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HallwayGame;