import React, { Component } from 'react';
import '././../../../../Styles/WhichProcess.scss';
import CustomImg from './../../../Global/CustomImg/CustomImg';

class WhichProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet: [],
            CurrentTask: {},
            allCompleted :  false,
            isGameCompleted :  false,
            AnswerAvailable : false,
            DefaultOption : "No",
            taskId: 0,
            Ports: [{ isCorrect: false }, { isCorrect: false }, { isCorrect: false }],
            Trains: [{ isCorrect: false }, { isCorrect: false }, { isCorrect: false }],
            Airports: [{ isCorrect: false }, { isCorrect: false }, { isCorrect: false }],
            Options : [
                { label : "OPTION0" ,  isCorrect: false , isSelected : false },
                { label : "OPTION1" ,  isCorrect: false , isSelected : false},
                { label : "OPTION2" ,  isCorrect: false , isSelected : false }],
            CollectionDropDown : [ "5 DECISIONS OR LESS" , "5 APPLICATIONS OR LESS" , "500 CLICKS OR LESS"]    
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                let stepCompleted = false;
                if(currentTask.Progress !== undefined && currentTask.Progress.length > 0)
                {
                    const verifyStepProgress = currentTask.Progress.filter(m=> m.type.trim().toLowerCase() === "step");
                    if(verifyStepProgress.length > 0)
                    {
                        stepCompleted = true;
                    }
                }
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id,
                    isGameCompleted : stepCompleted
                }
            }
        }
        return null;
    }

    verifyCellAnswers = () => {
        if(this.state.AnswerSheet && this.state.AnswerSheet.length > 0 && this.state.AnswerSheet[0].answer_key &&
            this.state.AnswerSheet[0].answer_key.length > 0  && this.state.AnswerSheet[0].answer_key[0])
        {
            const answers = this.state.AnswerSheet[0].answer_key;
            let allCorrected = true;
            const portAnswers = answers[0].CELL["PORTS"];
            const trainAnswers = answers[0].CELL["TRAINS"];
            const airPortAnswers = answers[0].CELL["AIRPORTS"];

            [...Array(3)].map((data, index) => {
                if (portAnswers[index].trim().toLowerCase() !== this.refs["PORTS" + index].value.trim().toLowerCase() ||
                trainAnswers[index].trim().toLowerCase() !== this.refs["TRAINS" + index].value.trim().toLowerCase() ||
                airPortAnswers[index].trim().toLowerCase() !== this.refs["AIRPORTS" + index].value.trim().toLowerCase()) {
                    allCorrected = false;
                }
            });

            if (allCorrected) {
                this.setState({
                    AnswerAvailable: true,
                    allCompleted: true
                });
            }
        }
    }

    verifyFinalAnswer =  async (type, index) => {
        if(this.state.AnswerAvailable)
        {
            let isCorrected = false;
            let updatedArray = this.state.Options;
            const answers = this.state.AnswerSheet[0].answer_key;
            if(type.trim().toLowerCase() === answers[0].ID.trim().toLowerCase())
            {
                updatedArray[index].isCorrect = true;
                updatedArray[index].isSelected = true;
                isCorrected = true;
            }else{
                updatedArray[index].isSelected = true;
                updatedArray[index].isCorrect = false;
            }

            this.setState({
                Options : updatedArray
            });

            if(isCorrected)
            {
                this.setState({
                    isGameCompleted: true
                });
                await this.props.markStepAsCompleted(this.state.taskId,1);
            }
        }
    }

    closeInstruction = async () => {
        await this.props.markAsCompleted(this.state.taskId);
    }

    selectDropDown =  (type, index , selectionType) =>{
        if(this.state.allCompleted)
        {
            return;
        }
        if(type.toLowerCase() === "ports")
        {
            this.refs["PORTS" + index].innerText = selectionType;
            this.refs["PORTS" + index].value = selectionType;
        }else if(type.toLowerCase() === "trains")
        {
            this.refs["TRAINS" + index].innerText = selectionType;
            this.refs["TRAINS" + index].value = selectionType;
        }else if(type.toLowerCase() === "airports")
        {
            this.refs["AIRPORTS" + index].innerText = selectionType;
            this.refs["AIRPORTS" + index].value = selectionType;
        }

        this.verifyCellAnswers();
    }

    render() {
        return (
            <React.Fragment>
                <div id="container" className="position-absolute d-flex jistify-content-center">
                    <div className="page_content position-absolute">
                        <div className="gate gate-one h-100 position-relative p-4">
                            <div className={"container-fluid mt-3"+ (this.state.isGameCompleted ? " d-none" : "")}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="nav nav-tabs nav-fill border-0" id="port-trains-airport" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link text-uppercase active" id="ports-tab" data-toggle="tab" href="#ports" role="tab" aria-controls="ports" aria-selected="true">ports</a>
                                            </li>
                                            <li className="nav-item mx-3">
                                                <a className="nav-link text-uppercase" id="trains-tab" data-toggle="tab" href="#trains" role="tab" aria-controls="trains" aria-selected="false">trains</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-uppercase" id="airports-tab" data-toggle="tab" href="#airports" role="tab" aria-controls="airports" aria-selected="false">airports</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content mt-5" id="port-trains-airportContent">
                                            <div className="tab-pane fade pt-3 show active" id="ports" role="tabpanel" aria-labelledby="ports-tab">
                                                <h2 className="my-5">PORTS</h2>
                                                <p className="mb-5 pb-3">There are 99 seaports in the USA that do more than 2 billion dollars in trade. These seaports are a crucial link between our country and the rest of the world. If these ports stay shut down much longer, our ability to import and export critical goods will be crippled. Each seaport uses a unique custom application to manage communications and controls. To automate this workflow would require working with 99 custom applications, using 3 decisions and less than 500 clicks.</p>
                                            </div>
                                            <div className="tab-pane fade pt-3" id="trains" role="tabpanel" aria-labelledby="trains-tab">
                                                <h2 className="my-5">TRAINS</h2>
                                                <p className="mb-5 pb-3">Once goods enter the country through the numerous seaports the goods are then moved around the country through an intricate network of trains and train depots. Over 40% of the goods moved throughout the US are moved on trains! This is another critical piece of our economy. The train system is a little less bifurcated then our ports, but would still require an automation to work with 9 diffrent applications. It would require 10 decisions & less than 100 clicks. Getting coms back online for this category will take about 20 minutes per port.</p>
                                            </div>
                                            <div className="tab-pane fade pt-3" id="airports" role="tabpanel" aria-labelledby="airports-tab">
                                                <h2 className="my-5">AIRPORTS</h2>
                                                <p className="mb-5 pb-3">There are 5170 airports in the USA that are open to the general public. Fortunately all of these utilize 1 applciation for air traffic control commands. To get coms back online will require roughly 40 minutes per airport unless we automate. To automate will require only 36 clicks & 4 decisions.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <table className="box-table text-center w-100">
                                            <tbody>
                                                <tr className="border-bottom-pink-yellow border-hight5">
                                                    <td></td>
                                                    <td className="border-right">PORTS</td>
                                                    <td className="border-right">TRAINS</td>
                                                    <td>AIRPORTS</td>
                                                </tr>
                                                {this.state.CollectionDropDown &&
                                                    this.state.CollectionDropDown.map((data, index) => {
                                                        return <tr>
                                                            <td className={(index !== 2 && "border-bottom")}>{data}</td>
                                                            <td className={"border-right " + (index !== 2 && " border-bottom")}><label className="d-none" htmlFor={"PORT" + index}>{"PORT" + index}</label>
                                                                <div className="form-group">                                                                    
                                                                    <div className="input-outer">
                                                                        <div className="dropdown select-dropdown-menu">
                                                                            <button disabled={this.state.allCompleted} ref={"PORTS"+ index} className={"btn dropdown-toggle"} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                &nbsp;
                                                                            </button>
                                                                            {!this.state.allCompleted &&
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                    <li onClick={() => this.selectDropDown("PORTS", index, "Yes")}>Yes</li>
                                                                                    <li onClick={() => this.selectDropDown("PORTS", index, "No")}>No</li>
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className={"border-right " + (index !== 2 && " border-bottom")}><label className="d-none" htmlFor={"TRAINS" + index}>{"TRAINS" + index}</label>
                                                            <div className="form-group">                                                                    
                                                                    <div className="input-outer">
                                                                        <div className="dropdown select-dropdown-menu">
                                                                            <button  disabled={this.state.allCompleted} ref={"TRAINS"+ index} className="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                &nbsp;
                                                                            </button>
                                                                            {!this.state.allCompleted &&
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                    <li onClick={() => this.selectDropDown("TRAINS", index, "Yes")}>Yes</li>
                                                                                    <li onClick={() => this.selectDropDown("TRAINS", index, "No")}>No</li>
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className={(index !== 2 && "border-bottom")}><label className="d-none" htmlFor={"AIRPORTS" + index}>{"AIRPORTS" + index}</label>
                                                                <div className="form-group">
                                                                    <div className="input-outer">
                                                                        <div className="dropdown select-dropdown-menu">
                                                                            <button  disabled={this.state.allCompleted} ref={"AIRPORTS" + index} className="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                &nbsp;
                                                                            </button>
                                                                            {!this.state.allCompleted &&
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                    <li onClick={() => this.selectDropDown("AIRPORTS", index, "Yes")}>Yes</li>
                                                                                    <li onClick={() => this.selectDropDown("AIRPORTS", index, "No")}>No</li>
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <table className="text-center w-100 mb-5">
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td><button onClick={() => this.verifyFinalAnswer("PORT", 0)} className="red-btn" tabIndex="10"><div className={(this.state.AnswerAvailable ? (this.state.Options[0].isSelected ? ( this.state.Options[0].isCorrect ? "redcolor easy-btn" : "redcolor") : "redcolor btn-success" ) : "redcolor btn-default")}><span>SELECT</span></div></button></td>
                                                    <td><button onClick={() => this.verifyFinalAnswer("TRAINS" , 1)} className="red-btn" tabIndex="11"><div className={(this.state.AnswerAvailable ? (this.state.Options[1].isSelected ? ( this.state.Options[1].isCorrect ? "redcolor easy-btn" : "redcolor") : "redcolor btn-success" ) : "redcolor btn-default")}><span>SELECT</span></div></button></td>
                                                    <td><button onClick={() => this.verifyFinalAnswer("AIRPORTS",2)} className="red-btn" tabIndex="12"><div className={(this.state.AnswerAvailable ? (this.state.Options[2].isSelected ? ( this.state.Options[2].isCorrect ? "redcolor easy-btn" : "redcolor") : "redcolor btn-success" ) : "redcolor btn-default")}><span>SELECT</span></div></button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="image-placeholder img-holder">
                                            <div className="responsive p-5">
                                            5 decisions or less - 5 apps or less - 500 clicks or less
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-popup-theme position-absolute justify-content-center align-items-center" + (this.state.isGameCompleted ? " d-flex" : " d-none")}>
                                <div className="modal-theme d-flex w-100 align-items-center p-3">
                                    <button className="close-theme position-absolute btn-link btn p-0" onClick={this.closeInstruction}><CustomImg src="close.png" className="img-fluid" alt="closeButton"/></button>
                                    <div className="position-relative mr-3 text-center w-25">
                                        <div className="responsive">
                                            <CustomImg src="man-icon.svg" className="rounded-circle" alt="Profile Image" />
                                        </div>
                                    </div>
                                    <div className="w-75">
                                        <p>Great!</p>
                                        <p>Now that we know we should automate the airport data uploads, lets decide how much time it will take us.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default WhichProcess;