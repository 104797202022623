import React, { Component } from 'react';
import store from "../../Redux/Store";
import CustomImg from '../Global/CustomImg/CustomImg';

class Footer extends Component {
    
    constructor() {
        super();
        this.state = {
            timer :2,
            isEnrolled : false,
            isGameFinish : false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isEnrolled !== state.isEnrolled) {
                return {
                    isEnrolled : props.isEnrolled
                }
        }

        if (props.isGameFinish !== state.isGameFinish) {
            return {
                isGameFinish : props.isGameFinish
            }
    }
       
        return null;
    }

    componentDidUpdate() {      
        if(this.state.isEnrolled) {  
            clearInterval(this.timer); 
            this.timer = setInterval(() => { this.props.markLastActivity() }, 60000);
        }

        if(this.state.isGameFinish) {  
            clearInterval(this.timer);            
            this.timer="";
        }
    }

    render() {
      
        return (<React.Fragment>
            <div className="bottom-section d-flex">
                <div className="col text-center mt-4">
                    <div className="row">
                        <div className="col pl-0">
                            <div className="nextwork-title">NETWORK DAMAGE</div>
                            <div className="world-progressBox mb-1">
                                <div className="progress-circle incomplte"></div>
                                <div className="progress-circle incomplte"></div>
                                <div className="progress-circle incomplte"></div>
                                <div className="progress-circle incomplte"></div>
                                <div className="progress-circle complte"></div>
                                <div className="progress-circle complte"></div>
                                <div className="progress-circle complte mr-2"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 p-0">
                                    <div className="progress"></div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="progress py-progress"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col bottom-chart">
                            <CustomImg width="100%" src="graf.svg" className="" alt="bottom-chart" />
                        </div>
                    </div>
                </div>
                <div className="button-scoreBox text-center col">
                    <button className="score-button btn text-center">
                        <span>SCORE: {this.props.totalScore}</span>
                    </button>
                </div>
                <div className="col text-left pl-0 buttom-button-action d-flex">
                    <div className="col p-0 col-sm-3">
                        <div className="bottom-circle">
                            <div className="black-circle">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 56.64 56.11">
                                    <defs>
                                        <clipPath id="clip-path" transform="translate(-6.52 -7.24)">
                                            <path
                                                d="M37.59,9.24a2.5,2.5,0,0,0,1.95,2.95A23.32,23.32,0,1,1,11.52,35a2.5,2.5,0,0,0-5,0,28.32,28.32,0,1,0,34-27.75,2.92,2.92,0,0,0-.5,0,2.51,2.51,0,0,0-2.45,2"
                                                style={{fill: 'none'}} />
                                        </clipPath>
                                        <linearGradient id="linear-gradient" x1="-653" y1="502" x2="-652" y2="502"
                                            gradientTransform="translate(37634.61 -28904.23) scale(57.63)" gradientUnits="userSpaceOnUse">
                                            <stop offset="0" stopColor="#ed2181" />
                                            <stop offset="0.32" stopColor="#f3555c" />
                                            <stop offset="1" stopColor="#febe10" />
                                        </linearGradient>
                                        <clipPath id="clip-path-2" transform="translate(-6.52 -7.24)">
                                            <path
                                                d="M26.7,16.87a2.5,2.5,0,1,0,2.05,4.56A14.91,14.91,0,1,1,19.94,35a2.5,2.5,0,1,0-5,0A19.9,19.9,0,1,0,26.7,16.87"
                                                style={{fill: 'none'}} />
                                        </clipPath>
                                        <linearGradient id="linear-gradient-2" x1="-654.65" y1="499.11" x2="-653.65" y2="499.11"
                                            gradientTransform="translate(26719.91 -20337.67) scale(40.8)" gradientUnits="userSpaceOnUse">
                                            <stop offset="0" stopColor="#8751a1" />
                                            <stop offset="1" stopColor="#5480c1" />
                                        </linearGradient>
                                        <clipPath id="clip-path-3" transform="translate(-6.52 -7.24)">
                                            <path d="M25.54,35a9.3,9.3,0,1,0,9.3-9.31A9.3,9.3,0,0,0,25.54,35" style={{fill: 'none'}} />
                                        </clipPath>
                                        <linearGradient id="linear-gradient-3" x1="-661.39" y1="487.31" x2="-660.39" y2="487.31"
                                            gradientTransform="translate(12320.88 -9036.04) scale(18.6)" gradientUnits="userSpaceOnUse">
                                            <stop offset="0" stopColor="#21203f" />
                                            <stop offset="0.62" stopColor="#212036" />
                                            <stop offset="1" stopColor="#21202d" />
                                        </linearGradient>
                                    </defs>
                                    <title>zxzx</title>
                                    <g style={{clipPath: "url(#clip-path)"}}>
                                        <rect width="56.64" height="56.11" style={{fill:"url(#linear-gradient)"}} />
                                    </g>
                                    <g style={{clipPath: "url(#clip-path-2)"}}>
                                        <rect x="8.42" y="7.89" width="39.8" height="39.8" style={{fill:"url(#linear-gradient-2)"}} />
                                    </g>
                                    <g style={{clipPath: "url(#clip-path-3)"}}>
                                        <rect x="19.02" y="18.49" width="18.6" height="18.6" style={{fill:"url(#linear-gradient-3)"}} />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col col-sm-4">
                        <div className="bottom-circle">
                            <div className="black-circle">
                                <svg id="Layer_1_b" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 56.64 56.11">
                                    <defs>
                                        <clipPath id="clip-path_b" transform="translate(-6.52 -7.24)">
                                            <path
                                                d="M37.59,9.24a2.5,2.5,0,0,0,1.95,2.95A23.32,23.32,0,1,1,11.52,35a2.5,2.5,0,0,0-5,0,28.32,28.32,0,1,0,34-27.75,2.92,2.92,0,0,0-.5,0,2.51,2.51,0,0,0-2.45,2"
                                                style={{fill: 'none'}} />
                                        </clipPath>
                                        <linearGradient id="linear-gradient_b" x1="-653" y1="502" x2="-652" y2="502"
                                            gradientTransform="translate(37634.61 -28904.23) scale(57.63)" gradientUnits="userSpaceOnUse">
                                            <stop offset="0" stopColor="#ed2181" />
                                            <stop offset="0.32" stopColor="#f3555c" />
                                            <stop offset="1" stopColor="#febe10" />
                                        </linearGradient>
                                        <clipPath id="clip-path-2_b" transform="translate(-6.52 -7.24)">
                                            <path
                                                d="M26.7,16.87a2.5,2.5,0,1,0,2.05,4.56A14.91,14.91,0,1,1,19.94,35a2.5,2.5,0,1,0-5,0A19.9,19.9,0,1,0,26.7,16.87"
                                                style={{fill: 'none'}} />
                                        </clipPath>
                                        <linearGradient id="linear-gradient-2_b" x1="-654.65" y1="499.11" x2="-653.65" y2="499.11"
                                            gradientTransform="translate(26719.91 -20337.67) scale(40.8)" gradientUnits="userSpaceOnUse">
                                            <stop offset="0" stopColor="#8751a1" />
                                            <stop offset="1" stopColor="#5480c1" />
                                        </linearGradient>
                                        <clipPath id="clip-path-3_b" transform="translate(-6.52 -7.24)">
                                            <path d="M25.54,35a9.3,9.3,0,1,0,9.3-9.31A9.3,9.3,0,0,0,25.54,35" style={{fill: 'none'}} />
                                        </clipPath>
                                        <linearGradient id="linear-gradient-3_b" x1="-661.39" y1="487.31" x2="-660.39" y2="487.31"
                                            gradientTransform="translate(12320.88 -9036.04) scale(18.6)" gradientUnits="userSpaceOnUse">
                                            <stop offset="0" stopColor="#21203f" />
                                            <stop offset="0.62" stopColor="#212036" />
                                            <stop offset="1" stopColor="#21202d" />
                                        </linearGradient>
                                    </defs>
                                    <title>zxzx</title>
                                    <g style={{clipPath :"url(#clip-path_b)"}}>
                                        <rect width="56.64" height="56.11" style={{fill: "url(#linear-gradient_b)"}} />
                                    </g>
                                    <g style={{clipPath :"url(#clip-path-2_b)"}}>
                                        <rect x="8.42" y="7.89" width="39.8" height="39.8" style={{fill: "url(#linear-gradient-2_b)"}} />
                                    </g>
                                    <g style={{clipPath :"url(#clip-path-3_b)"}}>
                                        <rect x="19.02" y="18.49" width="18.6" height="18.6" style={{fill:"url(#linear-gradient-3_b)"}} />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col mt-3">
                        <div className="row mt-4">
                            <div className="col-sm-4 p-0">
                                <div className="progress"></div>
                            </div>
                            <div className="col-sm-8">
                                <div className="progress py-progress"></div>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-sm-7 pl-0">
                                <div className="progress py-progress"></div>
                            </div>
                            <div className="col-sm-5 pl-0">
                                <div className="progress"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >);
    }
}

export default Footer;