import React, { Component } from 'react';
import '././../../../../Styles/FlightRestore.scss';
import CustomImg from './../../../Global/CustomImg/CustomImg';
import ReactCodeInput from './../../../Global/ReactCodeInput/ReactCodeInput'; 

class FlightRestore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet: [],
            CurrentTask: {},
            taskId: 0,
            isCompleted: false,
            isAllSelected : false,
            isValid:true,
            password:"",
            Options: [
                { isCorrect: false },
                { isCorrect: false },
                { isCorrect: false },
                { isCorrect: false }]
        };
    }

    componentDidMount() {
       // this.refs["option0"].focus(); 
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id,
                }
            }
        }
        return null;
    }

    verifyAnswers = async (value) => {
        this.setState({
            password: value
        });
        
        if(value.length == 4){
            const answers = this.state.AnswerSheet[0].answer_key;
            const passcode = answers[0].CODE;
            if (value.trim().toLowerCase() === passcode.trim().toLowerCase()) {
                this.setState({
                    isCompleted: true
                });

                setTimeout(async () => {
                    await this.props.markAsCompleted(this.state.taskId);
                }, 5000);
            }else{
                this.setState({
                    isValid: false
                });
            }
        }
        
       /* console.log(this,"key code",e, e.target.dataset.index,this.props);
        const currentInputIndex = e.target.dataset.index;
        if(currentInputIndex<4){
            this.refs["option"+currentInputIndex].focus();   
        }
        if (this.refs["option0"].value.length > 0
            && this.refs["option1"].value.length > 0
            && this.refs["option2"].value.length > 0
            && this.refs["option3"].value.length > 0) {
            const answers = this.state.AnswerSheet[0].answer_key;
            let updatedOptions = this.state.Options;
            let counter = 0;
            answers[0].CODE.map((data, key) => {
                //let index = key + 1;
                let optionsValue = this.refs["option" + key].value;
                if (data.trim().toLowerCase() === optionsValue.trim().toLowerCase()) {
                    updatedOptions[key].isCorrect = true;
                    counter++;
                }
            });

            this.setState({
                Options: updatedOptions,
                isAllSelected : true
            });

            if (counter === 4) {
                this.setState({
                    isCompleted: true
                });

                setTimeout(async () => {
                    await this.props.markAsCompleted(this.state.taskId);
                }, 5000);
            }
        }*/
    }

    render() {
        return (
            <React.Fragment>
                <div id="container" className="position-absolute d-flex jistify-content-center">
                    <div className="page_content">
                   <div className="positionFixed">
                   <div className="gate h-100 d-flex flex-column justify-content-center">
                            <div className={"flight-cloud position-relative" + (this.state.isCompleted ? " flight-animate" : "")}>
                                <div className="position-absolute cloud"><CustomImg src="cloud.png" className="img-fluid" alt="" /></div>
                                <div className="position-absolute plane-left"><CustomImg src="plane-left.svg" className="img-fluid" alt="" /></div>
                                <div className="position-absolute plane-right"><CustomImg src="plane-right.svg" className="img-fluid" alt="" /></div>
                                <div className="position-absolute line-left">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 190.04 90.34"><defs><linearGradient id="New_Gradient_Swatch_copy" x1="438.72" y1="110.43" x2="283.25" y2="-20.02" gradientTransform="matrix(-1, 0, 0, 1, 455.86, 0)" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#ff0381" /><stop offset="0.43" stopColor="#ff5549" /><stop offset="1" stopColor="#ffbe00" /></linearGradient></defs><g id="Layer_2" data-name="Layer 2"><g id="Border"><path style={{ fill: "none", strokeMiterlimit: 10, strokeDasharray: 3.66, stroke: "url(#New_Gradient_Swatch_copy)" }} className="cls-1 path-left" d="M189.73.39S124.49,53.33.14,89.86" /></g></g></svg>
                                </div>
                                <div className="position-absolute line-right"><CustomImg src="line-right.svg" className="img-fluid" alt="" /></div>
                                <div className="position-absolute tower"><CustomImg src="tower.svg" className="img-fluid" alt="" /></div>
                            </div>
                            <div className="answer-box border-bottom-0 border-left-0 border-right-0 d-flex justify-content-center py-5">
                                <ReactCodeInput ref={"password"} type='text' fields={4} pattern="\d*" className={"code-input" + (this.state.password.length == 4 && !this.state.isValid ? " invalid" : (this.state.password.length == 4) ? " valid" : "")} onChange={this.verifyAnswers} disabled={this.state.isCompleted} value={this.state.password} />
                                {/* <div class="mx-3 input-outer"><input pattern="\d*" maxLength="1" disabled={this.state.Options[0].isCorrect} onChange={() => this.verifyAnswers(0)} ref={"option0"} type="text" className={"form-control" + (this.state.isAllSelected && !this.state.Options[0].isCorrect ? " bg-danger" : "")} /></div>
                                <div class="mx-3 input-outer"><input pattern="\d*" maxLength="1" disabled={this.state.Options[1].isCorrect} onChange={() => this.verifyAnswers(1)} ref={"option1"} type="text" className={"form-control" + (this.state.isAllSelected && !this.state.Options[0].isCorrect ? " bg-danger" : "")} /></div>
                                <div class="mx-3 input-outer"><input pattern="\d*" maxLength="1" disabled={this.state.Options[2].isCorrect} onChange={() => this.verifyAnswers(2)} ref={"option2"} type="text" className={"form-control" + (this.state.isAllSelected && !this.state.Options[0].isCorrect ? " bg-danger" : "")} /></div>
        <div class="mx-3 input-outer"><input pattern="\d*" maxLength="1" disabled={this.state.Options[3].isCorrect} onChange={() => this.verifyAnswers(3)} ref={"option3"} type="text" className={"form-control" + (this.state.isAllSelected && !this.state.Options[0].isCorrect ? " bg-danger" : "")} /></div>*/}
                            </div>
                        </div>
                   </div>
                       
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default FlightRestore;