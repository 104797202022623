import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import '././../../../../Styles/DesignWorkflow.scss';
import CustomImg from './../../../Global/CustomImg/CustomImg';
import Draggable from "react-draggable";
import { const_basePage } from '../../../Global/Constants/constants';

class DesignWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdge : false,
            lastPostion: false,
            isPositionCompleted: false,
            draggedElementPosition: 0,
            draggedElement: "0",
            draggedElementText : "",
            isGameCompleted: false,
            AnswerSheet: [],
            CurrentTask: {},
            taskId: 0,
            DraggablePositions: {},
            CORRECTPOSITION: [false, false, false, false, false, false],
            TitleList: [
                { INDEX : 1 , ID: "Input Dialog", isCorrect: false },
                { INDEX : 2 , ID: "Assign", isCorrect: false },
                { INDEX : 3 , ID: "Open Browser", isCorrect: false },
                { INDEX : 4 , ID: "Type Into", isCorrect: false },
                { INDEX : 5 , ID: "Assign", isCorrect: false },
                { INDEX : 6 , ID: "Assign", isCorrect: false },
                { INDEX : 7 , ID: "Open Application", isCorrect: false },
                { INDEX : 8 , ID: "Get Value", isCorrect: false }
            ]
        };
    }

    componentDidMount()
    {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        const isEdge = !isIE && !!window.StyleMedia;
        this.setState({
            isEdge: isEdge
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                let stepCompleted = false;
                if (currentTask.Progress !== undefined && currentTask.Progress.length > 0) {
                    const verifyStepProgress = currentTask.Progress.filter(m => m.type.trim().toLowerCase() === "step");
                    if (verifyStepProgress.length > 0) {
                        stepCompleted = true;
                    }
                }
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id,
                    isGameCompleted: stepCompleted
                }
            }
        }
        return null;
    }


    onStart = (index , elementTxt) => {
        this.setState({
            draggedElement: index.toString(),
            draggedElementText : elementTxt,
            lastPostion: false
        });
    }

    onDrag = (e, data) => {
        if (!this.state.lastPostion && e.y > 430) {
            this.setState({
                lastPostion: true
            });
        }
    }

    handleStop = async (e, data, index) => {
        const mouseangle = e.x;
        let isInside = false;
        let inputId = 0;
        const answers = this.state.AnswerSheet[0].answer_key[0];
        if (this.state.lastPostion) {
            [...Array(6)].map((data, key) => {
                const txtField = ReactDOM.findDOMNode(this.refs["txt" + (key + 1)]).getBoundingClientRect();
                if(this.state.isEdge)
                {
                    if (mouseangle > txtField.left && mouseangle < (txtField.left + txtField.width)) {
                        inputId = key;
                        isInside = true;
                    }
                } else {
                    if (mouseangle > txtField.x && mouseangle < (txtField.x + txtField.width)) {
                        inputId = key;
                        isInside = true;
                    }
                }
            });

            if (isInside) {
                if (inputId === 3 || inputId === 5) {
                    if (answers.POSITION[inputId].trim().toLowerCase() === this.state.draggedElementText.trim().toLowerCase() && this.state.draggedElement === "2" && inputId === 3) {
                        let updatedCorrectPosition = this.state.CORRECTPOSITION;
                        updatedCorrectPosition[inputId] = true;
                        let updatedPosition = this.state.TitleList;
                        updatedPosition[1].isCorrect = true;
                        this.setState({
                            TitleBox: updatedPosition,
                            CORRECTPOSITION: updatedCorrectPosition
                        });
                    }else if (answers.POSITION[inputId].trim().toLowerCase() === this.state.draggedElementText.trim().toLowerCase() && this.state.draggedElement=== "7" && inputId === 5) {
                        let updatedCorrectPosition = this.state.CORRECTPOSITION;
                        updatedCorrectPosition[inputId] = true;
                        let updatedPosition = this.state.TitleList;
                        updatedPosition[6].isCorrect = true;
                        this.setState({
                            TitleBox: updatedPosition,
                            CORRECTPOSITION: updatedCorrectPosition
                        });
                    }
                } else {
                    if (answers.POSITION[inputId].trim().toLowerCase() === this.state.draggedElementText.trim().toLowerCase()) {
                        let updatedCorrectPosition = this.state.CORRECTPOSITION;
                        updatedCorrectPosition[inputId] = true;
                        let updatedPosition = this.state.TitleList;
                        updatedPosition[this.state.draggedElement - 1].isCorrect = true;
                        this.setState({
                            TitleBox: updatedPosition,
                            CORRECTPOSITION: updatedCorrectPosition
                        });
                    }
                }
            }

            this.setState({
                draggedElement: 0
            });

            const totalAnswers = this.state.CORRECTPOSITION.filter(m => m);
            if (totalAnswers.length === 6) {
                this.setState({
                    isGameCompleted: true
                });
                await this.props.markStepAsCompleted(this.state.taskId, 1);
            }
        }
    }

    closeInstruction = async (e) => {
        e.preventDefault();
        await this.props.markAsCompleted(this.state.taskId);
    }

    render() {
        return (
            <React.Fragment>
                <div id="container" className="position-absolute d-flex jistify-content-center">
                    <div className="page_content position-absolute">
                        <div className="gate h-100 d-flex flex-column">
                            <h3 className="text-center mt-5 text-uppercase mb-0">Bot Building</h3>
                            <div className="mb-auto h-100 d-flex flex-wrap align-items-center">
                                {this.state.TitleList.map((data, key) => {
                                    const index = key + 1;
                                    return (
                                        <div className={"col-md-3"} key={key}>
                                            {!data.isCorrect &&
                                                <Draggable onDrag={this.onDrag} onStart={() => this.onStart(index, data.ID)} key={key} onStop={(e, data) => this.handleStop(e, data, index)} position={{ x: 0, y: 0 }} scale={const_basePage.scale}>
                                                    <div ref={"img" + key} id={"img" + key} className="drag position-relative">
                                                        <CustomImg src={"drag-" + index + ".svg"} className="img-fluid" alt="" />
                                                        <h4 className="position-absolute m-0 d-flex justify-content-center align-items-center">{data.ID}</h4>
                                                    </div></Draggable>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="answer-box border-0 pb-4 topline" ref="answersection">
                                <CustomImg src="dashed-line.svg" className="img-fluid" alt="" />
                                <h2 className="text-center text-uppercase my-4">Workflow</h2>
                                <div className="d-flex justify-content-center mx-4">
                                    {this.state.AnswerSheet && this.state.AnswerSheet.length > 0 &&
                                    this.state.AnswerSheet[0].answer_key && this.state.AnswerSheet[0].answer_key.length > 0
                                    && this.state.AnswerSheet[0].answer_key[0].POSITION && this.state.AnswerSheet[0].answer_key[0].POSITION.length > 0 
                                    && this.state.AnswerSheet[0].answer_key[0].POSITION.map((data, key) => {
                                        const index = key + 1;
                                        return <div id={"txt" + index} key={index} ref={"txt" + index} className={"answer mx-1 w-100 position-relative" + (this.state.CORRECTPOSITION[key] ? " correct" : "")}>
                                            {this.state.CORRECTPOSITION[key] &&
                                                <div className="drag position-relative">
                                                    <CustomImg src={"drag-ans-" + index + ".svg"} className="img-fluid" alt="" />
                                                    <h4 className="position-absolute m-0 d-flex justify-content-center align-items-center">{data}</h4>
                                                </div>}
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className={"game3 modal-popup-theme position-absolute justify-content-center align-items-center" + (this.state.isGameCompleted ? " d-flex" : " d-none")}>
                                <div className="modal-theme d-flex w-100 align-items-center p-3">
                                <button className="close-theme position-absolute  btn-link btn p-0" onClick={this.closeInstruction}><CustomImg src="close.png" className="img-fluid" alt="closebtn" /></button>
                                    <div className="position-relative w-100 mr-3">
                                        <div className="responsive">
                                            <CustomImg src="man-icon.svg" className="rounded-circle" alt="Profile Image" />
                                        </div>
                                    </div>
                                    <div>
                                        <p>Great!</p>
                                        <p>This looks like it will work. I'll start whipping this bot up.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DesignWorkflow;