import React, { Component } from 'react';
import Modal from './../../Global/Modal/Modal';
import CustomScrollbars from './../../Global/CustomScrollbars/CustomScrollbars';
import History from '../../../Providers/History';
import CustomImg from '../../Global/CustomImg/CustomImg';
import Player from '@vimeo/player';
const games = ["Which Process", "Time Savings", "Design Workflow" , "Input Key", "Hallway Game", "Flight Restore"];
//const games = ["Which Process", "Input Key", "Flight Restore"];

class Video extends Component {
    constructor() {
        super();
        this.state = {
          containershow : true,  
          modal: false,
          video:'',
          taskName:'',
          top: 0 , 
          task_id  : 0,
          isExperienceVideo : false,
          isFinishVideo : false ,
          videos : [],
          isFinalShow : false 
        };
      }

    playVideo = async (video) => {  
        const type = this.props.match.params.tid;    
        if (type === "experience") {
            //API call here
            this.props.markExperienceAsRead();
        } else if (type === "finish") {
            //API call here
            await this.props.markExperienceCompleted();
            History.push('/finish');
        }  else {
            this.setState({ modal: !this.state.modal, video: video }, () => {
                if(this.state.modal && video.length > 0)    
                {
                    this.videoInitialization(video);
                }
                if (this.props.match.params.tid >= 0 && !this.state.modal) {
                    // console.log(" Mark Video Read ");
                    /* Mark video as read */
                    const {gate_order:task_id} = this.props.currentTask;       
                    this.props.markCurrentTaskVideoPlayed(task_id);
                }
            });
        }
    }

    videoInitialization = (videoId) => {
        var options = {
            url: "https://vimeo.com/" + videoId,
            autoplay: true,
            muted: false,
            controls : true,
            speed : true
        };
        let player = new Player("modalFrame",options);
        player.setVolume(1);
    }

    playExperienceVideo = () => {
        this.setState({
            modal: true,
            isExperienceVideo : true,
            isFinishVideo : false
        }, () => { 
            this.videoInitialization(this.props.video_start);
        });
    }

    playFinishVideo = () => {
        this.setState({
            modal: true,
            isFinishVideo : true,
            isExperienceVideo : false
        }, () => { 
            this.videoInitialization(this.props.video_end);
        });
    }

    async componentDidMount() {
        const {isVideoPlayed, gate_order:task_id} = this.props.currentTask;       
        const curentVideoId = task_id;       
        this.setState({
            task_id : task_id
        });
        let response =  await this.props.getAllVideos(curentVideoId);
        const type = this.props.match.params.tid;
        if(type === "experience")
        {
            this.setState({
                containershow : false,
                modal: true,
                isExperienceVideo : true
            });
            this.videoInitialization(this.props.video_start);
        }else if(type === "finish")
        {
            this.setState({
                containershow : false,
                modal: true,
                isFinishVideo : true
            });
            this.videoInitialization(this.props.video_end);
        }else if(type === undefined || type === "undefined")
        {
            if (response !== "") {
                let updatedArray = response.splice(0,response.length-1);
                this.setState({
                    modal: false,
                    taskName: this.props.task_name,
                    video: updatedArray,
                    videos : updatedArray
                })
            }

            if(task_id === undefined || task_id === "undefined")
            {
                let finalResponse =  await this.props.getAllVideos(6);
                if (finalResponse !== "") {
                    this.setState({
                        modal: false,
                        taskName: this.props.task_name,
                        video: finalResponse,
                        videos : finalResponse,
                        isFinalShow : true
                    })
                }
            }
        }else{
            if (response !== "") {
                if (!isVideoPlayed) {
                    this.setState({
                        containershow : false,
                        modal: true,
                        taskName: this.props.task_name,
                        videos : response,
                        video:  response[response.length - 1]
                    }, () => {
                        if(this.state.modal)
                        {
                            this.videoInitialization(response[response.length - 1]);
                        }
                    })
                }
            }
        }
    }

    closeSection = () =>
    {
        if(this.props.isGameFinish)
        {
            History.push('/finish');
        }else{
            History.push('/task' + this.state.task_id);
        }
    }

    render() {
        return (<React.Fragment>
            <div className="videos-holder mainBox video-holder" style={{display : (this.state.containershow ? "block" : "none")}}>
                <div className="play-icon">
                    <em className="icon-playicon moonicon"></em>
                </div>
                <div className="container-close" onClick={this.closeSection}>
                    <CustomImg src="gradiant-close.svg" className="container-close float-right" alt="gradiant-close" />                
                </div>
                <CustomScrollbars>
                   <div id="videos-scrollbar">
                    <div className="video-Content">
                            <div className="row">
                                <div key={"experience"} className="col-4">
                                    <div className="video-list">
                                        <div>
                                            <div className="video-img">
                                                <div className="content">                                                      
                                                        <div className="overlay" style={{position: "absolute",height: "100%" , width: "100%"}} onClick={this.playExperienceVideo}></div>
                                                        {this.props.video_start && 
                                                            <iframe src={"https://player.vimeo.com/video/" + this.props.video_start + "?" + Math.random().toString(36).substring(5) + "&controls=0"}  frameBorder="0" width="100%" height="100%" allowFullScreen></iframe>
                                                        }
                                                </div>
                                            </div>
                                            <h4 className="title" onClick={this.playExperienceVideo}>Experience Video</h4>
                                        </div>
                                    </div>
                                </div>
                                {this.state.videos && this.state.videos.map((data, index) => {
                                    if(data)
                                    {
                                        return (<div key={index} className="col-4">
                                            <div className="video-list">
                                                <div>
                                                    <div className="video-img">
                                                        <div className="content">                                                      
                                                            <div className="overlay" style={{position: "absolute",height: "100%" , width: "100%"}} onClick={() => this.playVideo(data)}></div>
                                                            {data && 
                                                                <iframe src={"https://player.vimeo.com/video/" + data + "?" + Math.random().toString(36).substring(5) + "&controls=0"}  frameBorder="0" width="100%" height="100%" allowFullScreen></iframe>
                                                            }
                                                        </div>
                                                    </div>
                                                    <h4  onClick={() => this.playVideo(data)} className="title">{games[index]}</h4>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                })}
                                {this.props.isGameFinish &&
                                    <div key={"finish"} className="col-4">
                                        <div className="video-list">
                                            <div>
                                                <div className="video-img">
                                                    <div className="content">                                                      
                                                            <div className="overlay" style={{position: "absolute",height: "100%" , width: "100%"}} onClick={this.playFinishVideo}></div>
                                                            {this.props.video_end && 
                                                                <iframe src={"https://player.vimeo.com/video/" + this.props.video_end + "?" + Math.random().toString(36).substring(5) + "&controls=0"}  frameBorder="0" width="100%" height="100%" allowFullScreen></iframe>
                                                            }
                                                    </div>
                                                </div>
                                                <h4 className="title" onClick={this.playFinishVideo}>Finish Video</h4>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                    </div>
                </div>
                </CustomScrollbars>
            </div>
            {(this.state.modal )&& <Modal isOpen={this.state.modal} modalClass={"bd-example-modal-xl video-modal"} dialogClass={"modal-lg modal-dialog-centered"} >
                    <div className="modal-body">
                    <button onClick={() => this.playVideo('')} className="close-gradiant" data-dismiss="modal" aria-label="Close">
                        <CustomImg src="gradiant-close.svg" className="" alt="gradiant-close" />
                    </button>
                        <div className="w-100 h-100">                            
                                <div id="modalFrame" className="w-100 h-100 modalframe"></div>
                                {/* <iframe width="100%" height="100%" src="" allowFullScreen></iframe> */}
                                {/* {this.state.video && !this.state.isExperienceVideo && !this.state.isFinishVideo && 
                                <iframe ref="iframe" width="100%" height="100%" src={this.state.video + "?"+ Math.floor((Math.random() * 1000) + 1) + "&controls=0&muted=1&autoplay=1&loop=1&autopause=0"} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>} 
                                {this.state.isExperienceVideo && <iframe ref="iframe" width="100%" height="100%" src={this.props.video_start + "?"+ Math.floor((Math.random() * 1000) + 1) +"&controls=0&muted=1&autoplay=1&loop=1&autopause=0"} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
                                {this.state.isFinishVideo && <iframe ref="iframe" width="100%" height="100%" src={this.props.video_end + "?"+ Math.floor((Math.random() * 1000) + 1) +"&controls=0&muted=1&autoplay=1&loop=1&autopause=0"} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>} */}
                           
                        </div>
                    </div>
                    </Modal>
                }
        </React.Fragment >);
    }
}

export default Video;