import React, { Component } from 'react';
import '././../../../../Styles/TimeSavings.scss';
import Player from '@vimeo/player';
import ReactCodeInput from './../../../Global/ReactCodeInput/ReactCodeInput';

class TimeSavings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnswerSheet: [],
            CurrentTask: {},
            isGameCompleted: false,
            taskId: 0,
            currentMath: Math.floor((Math.random() * 1000) + 1),
            Options: [
                { isCorrect: false, isWrong: false },
                { isCorrect: false, isWrong: false },
                { isCorrect: false, isWrong: false },
                { isCorrect: false, isWrong: false }]
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id
                }
            }
        }
        return null;
    }

    onKeyPress = (e) => {
        var code = e.keyCode || e.which;
        if (code >= 48 && code <= 57) {
        } else {
            e.preventDefault();
        }
    }

    onKeyUp = (e) => {
        if (e.target.value.length === 1 && e.target.value === "0") {
            e.target.value = "";
        } else {
            this.verifyAnswer();
        }
    }

    verifyAnswer = async () => {
        if (this.state.AnswerSheet && this.state.AnswerSheet.length > 0 && this.state.AnswerSheet[0].answer_key &&
            this.state.AnswerSheet[0].answer_key.length > 0 && this.state.AnswerSheet[0].answer_key[0]) {
            const answers = this.state.AnswerSheet[0].answer_key[0];
            const txtWorkHours = this.refs["txtWorkHours"].value.toString();
            if (answers.WORKHOURS.trim().toLowerCase() === txtWorkHours.trim().toLowerCase()) {
                this.setState({
                    isGameCompleted: true
                }, async () => {
                    await this.props.markAsCompleted(this.state.taskId);
                });
            }
        }
    }

    componentDidMount() {
        this.videoInitialization("video1", process.env.REACT_APP_TIME_SAVING_VIDEO_FIRST);
    }

    videoInitialization = (frameId, videoId) => {
        var options = {
            url: "https://vimeo.com/" + videoId + "?" + Math.floor(Math.random() * 100) + 1,
            autoplay: true,
            muted: false,
            controls: true,
            speed: true
        };

        let player = new Player(frameId, options);
        player.setVolume(1);
    }

    render() {
        return (
            <React.Fragment>
                <div id="container" className="position-absolute d-flex jistify-content-center">
                    <div className="page_content position-absolute">
                        <div className="gate h-100 d-flex flex-column">
                            <div className="mb-auto position-relative h-100 d-flex justify-content-center align-items-center">
                                <div className="embed-responsive embed-responsive-16by9 w-50 box-line">
                                    <div className="embed-responsive-item" id="video1"></div>
                                </div>
                            </div>
                            <div className="answer-box border-0 d-flex justify-content-center pt-0">
                                <div className="col-6">
                                    <div className="row d-flex justify-content-center pb-5 mb-5">
                                        <div className="col-4">
                                            <div className={"input-outer"}>
                                                <input disabled={this.state.isGameCompleted} ref="txtWorkHours" onKeyUp={this.onKeyUp} onKeyPress={this.onKeyPress} type="text" className={"form-control"} />
                                            </div>
                                        </div>
                                        <div className="col col-auto">
                                            <div className="work-hours-title">
                                                Work Hours
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TimeSavings;