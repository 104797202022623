import { connect } from 'react-redux';
import { markAsCompleted,setCurrentTask, markStepAsCompleted } from '../../../Redux/Modules/Tasks';
import InputKey from '../../../Components/PageContainer/Games/InputKey/InputKey';

const mapStateToProps = ({ TaskReducer }) => {
    // console.log("Access Threat 1", TaskReducer);
    return {             
        CurrentTask : TaskReducer.CurrentTask
    };
};

const mapDispatchToProps = dispatch => ({
    markAsCompleted: (tid) => dispatch(markAsCompleted(tid)),
    setCurrentTask: (tid) => dispatch(setCurrentTask(tid)),
    markStepAsCompleted : (tid,itId) => dispatch(markStepAsCompleted(tid,itId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputKey);