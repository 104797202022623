import React, { Component } from 'react';
import './../../../Styles/Profile.scss';
import CustomImg from '../../Global/CustomImg/CustomImg';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
           currentImage : 0,
           value: "",
           lengthMsg : "",
           message : "",
           status : ""
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.message !== state.message) {
            return {
                message: props.message,
                status : props.status
            }
        }
        return null;
    }

    componentWillUnmount(){
        this.setState({
            message: '',
            status: ''
        });
      }

      componentDidMount(){
        this.setState({
            message: '',
            status: ''
        });
      }

    componentDidUpdate(){
        setTimeout(() =>  {
            this.props.disAppearMsg();
        }, 6000);
      }

    updatProfileInfo = () => {
        const profileName = this.state.value;
        this.props.updateUserName(profileName);
    }

    onChange = event => {
        this.setState({ 
            value: event.target.value.replace(/[^\w\s]/gi, "").replace(/ /g, ''),
         });
    };


    render() {
        return (<React.Fragment>
            <div className="videos-holder mainBox profile-holder">
                <div className="d-flex align-items-center h-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="profile-holder-box">
                                <div className="content">
                                    <div className="text-center col">
                                    <div className="profileBox mx-auto selected-profile mt-2">
                                            <div className="profileBox-imgbox" href="#">
                                                <CustomImg src="man-icon.svg" className="" alt="Profile Image" />
                                            </div>
                            </div>
                            <p class="mb-0 mt-5 text-truncate">NAME: ROBO_RABBIT</p>
                                <p className="text-truncate">NICKNAME: {this.props.nickname}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center text-white">
                            <div class="profile-rightbox">
                           <p class="mb-0">Create a nickname so we can track your score and progress!</p>
                           <p>No Spaces please!</p>
                            <div className="form-group mx-auto position-relative">
                                <div className="input-outer">
                                    <label htmlFor="nickname" className="d-none">nickname</label>
                                    <input id="nickname"  value={this.state.value} type="text" className="form-control" 
                                        aria-describedby="emailHelp" onChange={this.onChange}  />
                                </div>
                            </div>
                            <div className={"clearfix"}></div>
                            <p className={"mt-2 mb-0 " + this.state.status}>{this.state.message}</p>
                            <div className="form-group mx-auto position-relative">
                                <button className="red-btn mt-5" onClick={this.updatProfileInfo} type="button">
                                    <div className="redcolor easy-btn">
                                        <span>submit</span>
                                    </div>
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </React.Fragment>);
    }
}

export default Profile;