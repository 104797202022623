import React, { Component } from 'react';
import '././../../../../Styles/InputKey.scss';
import CustomImg from './../../../Global/CustomImg/CustomImg';
import ReactCodeInput from './../../../Global/ReactCodeInput/ReactCodeInput';

class InputKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Colors: ["white", "blue", "green", "pink", "red", "yellow"],
            Satelites: [
                { isGr: true, ID: 0, Lable: "ABCDEFG", skColor: "blue", grColor: "white", lock: false, grClass: "satellite-one", skClass: "satellite-two" },
                { isGr: false, ID: 1, Lable: "EFGHIJK", skColor: "green", grColor: "yellow", lock: false, grClass: "satellite-four", skClass: "satellite-three" },
                { isGr: true, ID: 2, Lable: "LMNOPQR", skColor: "red", grColor: "pink", lock: false, grClass: "satellite-five", skClass: "satellite-six" },
                { isGr: false, ID: 3, Lable: "STUVWXY", skColor: "white", grColor: "blue", lock: false, grClass: "satellite-eight", skClass: "satellite-saven" },
                { isGr: true, ID: 4, Lable: "PNGTUMS", skColor: "red", grColor: "green", lock: false, grClass: "satellite-nine", skClass: "satellite-ten" },
                { isGr: false, ID: 5, Lable: "IHGFDFY", skColor: "yellow", grColor: "pink", lock: false, grClass: "satellite-twelve", skClass: "satellite-eleven" }
            ],
            AnswerSheet: [],
            CurrentTask: {},
            taskId: 0,
            isValid: true,
            password: "",
            passwordCompleted: false,
            messageCompleted: false,
            isGameLoaded: false,
            Options: [
                { isCorrect: false },
                { isCorrect: false },
                { isCorrect: false },
                { isCorrect: false },
                { isCorrect: false },
                { isCorrect: false },
                { isCorrect: false }]
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.CurrentTask !== state.CurrentTask) {
            let currentTask = props.CurrentTask;
            let currentTaskSolutions = currentTask.Solutions;
            if (currentTask.Solutions !== undefined) {
                let passwordCompleted = false;
                let messageCompleted = false;
                if (currentTask.Progress !== undefined && currentTask.Progress.length > 0) {
                    const verifyStepProgress = currentTask.Progress.filter(m => m.type.trim().toLowerCase() === "step");
                    if (verifyStepProgress.length === 1) {
                        passwordCompleted = true;
                    } else if (verifyStepProgress.length === 2) {
                        messageCompleted = true;
                        passwordCompleted = true;
                    }
                }
                return {
                    AnswerSheet: currentTaskSolutions,
                    taskId: currentTask.id,
                    passwordCompleted: passwordCompleted,
                    messageCompleted: messageCompleted,
                    isGameLoaded: true
                }
            }
        }
        return null;
    }

    verifyStep = async (value) => {
        this.setState({
            password: value
        });

        if (value.length == 7) {
            const answers = this.state.AnswerSheet[0].answer_key;
            const passcode = answers[0].CODE;
            if (value.trim().toLowerCase() === passcode.trim().toLowerCase()) {
                this.setState({
                    passwordComplete: true
                });
                await this.props.markStepAsCompleted(this.state.taskId, 1);
            } else {
                this.setState({
                    isValid: false
                });
            }
        }
    }

    closeMessageInstruction = async (e) => {
        e.preventDefault();
        this.setState({
            messageCompleted: true
        });
        await this.props.markAsCompleted(this.state.taskId);
    }

    render() {
        return (
            <React.Fragment>
                <div id="container">
                    <div className="page_content">
                        <div className="gate-five">
                            {this.state.isGameLoaded && <React.Fragment>
                                <div className="gate-fivebackground">
                                    <CustomImg src="gate5-background.svg" alt="" />
                                    <div className="linesbox">
                                        <div className="lineBox"></div>
                                        <div className="lineBox"></div>
                                        <div className="lineBox"></div>
                                        <div className="lineBox"></div>
                                        <div className="lineBox"></div>
                                    </div>
                                </div>
                                <div className={"overlayboxnew" + (this.state.messageCompleted ? " d-none" : "")}>
                                    <div className={"modal-popup-theme position-absolute justify-content-center align-items-center" + (this.state.passwordCompleted ? (this.state.messageCompleted ? " d-none" : " d-flex") : " d-none")}>
                                        <div className="modal-theme d-flex w-100 align-items-center p-3">
                                            <button className="close-theme position-absolute btn-link btn p-0" onClick={this.closeMessageInstruction}><CustomImg src="close.png" className="img-fluid" alt="closebtn" /></button>
                                            <div className="position-relative w-25 mr-3">
                                                <div className="responsive">
                                                    <CustomImg src="man-icon.svg" className="rounded-circle" alt="Profile Image" />
                                                </div>
                                            </div>
                                            <div className="w-75">
                                                <p>Hey, you! I got somethin’ to tell ya.</p>
                                                <p>Dialogue goes here.  Lorem ipsum dolor amet narwhal forage af skateboard austin knausgaard jean shorts VHS. Organic flannel try-hard, YOLO etsy actually neutra shabby chic sriracha.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"modal-popup-theme position-absolute justify-content-center align-items-center d-none" + (this.state.passwordCompleted ? " d-none" : " d-flex")}>
                                        <div className="modal-theme d-flex w-100 align-items-center p-3 bg-black">
                                            {/*<button onClick={ (e) => e.preventDefault() } className="close-theme position-absolute btn btn-link p-0"><CustomImg src="close.png" className="img-fluid" alt="closebtn" /></button>*/}
                                            <div className="p-5">
                                                <h4>you must enter the code below to continue.</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"enter-boxnew" + (this.state.passwordCompleted ? " d-none" : " ")}>
                                        <div className="type-box">
                                            <ReactCodeInput ref={"password"} type='text' fields={7} pattern="\d*" className={"code-input" + (this.state.password.length == 7 && !this.state.isValid ? " invalid" : (this.state.password.length == 7) ? " valid" : "")} onChange={this.verifyStep} disabled={this.state.isCompleted} value={this.state.password} />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default InputKey;